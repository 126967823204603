import React, {useEffect} from 'react'
import {dotenv, routes} from "../../../../../config/config";
import {MenuAccordions} from "../../../../../components/MenuAccordion/MenuAccordions";
import {l} from "../../../../../library/locale";
import {ButtonsLeftMenu} from "./Button/ButtonsLeftMenu";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    LoadingOutlined,
    BookOutlined,
    RobotOutlined,
    SettingOutlined,
    TrophyOutlined,
    UserOutlined,
    ShoppingOutlined,
    FormOutlined,
    AppstoreOutlined,
    MessageOutlined,
    DesktopOutlined,
    ExportOutlined,
    LineChartOutlined,
} from "@ant-design/icons";
import {getUtcDateTime, inArray, removeValueFromArray} from "../../../../../library/functions";

let project_item = {}

const LeftMenu = ({bottom, admin, dispatch}) => {
    const {project_id} = useParams()
    const project_item_id = parseInt(project_id)
    const {project} = useSelector(store => store)
    project_item = project.item

    const {site} = useSelector(store => store)

    if (bottom) {
        if (!project_item_id) return null

        const main_menu_items = [
            {
                name: 'help',
                title: l('menu.help'),    // Остров знаний
                buttonIcon: BookOutlined,
                path: dotenv.help_url,
                external: true,
            },
            {
                name: 'social',
                title: l('menu.social_club'),
                buttonIcon: ExportOutlined,
                path: dotenv.social_club_url,
                external: true,
            },
            {
                name: 'tg',
                title: l('menu.main_bot'),
                buttonIcon: FormOutlined,
                path: dotenv.main_bot_url,
                external: true,
            },
        ]

        return <ButtonsLeftMenu menuBtn={main_menu_items}/>
    }

    if (project_item_id) {
        if (!project_item.id || project_item.id !== project_item_id) {
            return <div className="margin-top-xs align-center color-secondary">
                <LoadingOutlined style={{fontSize: 30}}/>
            </div>
        }

        let main_menu_project = {
            templates: { // Шаблоны
                items: [
                    'store',           // Магазин шаблонов
                    'template',           // Установленные в проект
                    // 'created',         // Созданные мной
                ],
                iconHeader: AppstoreOutlined,
            },
            automation: { // Автоматизация
                items: [
                    'graph',  // Блок-схемы
                    'glob',  // Глобальные переменные
                    'url',  // Короткие ссылки
                    'webhook', // вебхуки
                    // 'funnel',  // Воронки
                ],
                iconHeader: RobotOutlined,
            },
            contact: { // Общение
                items: [
                    'dialog',  // Диалоги
                    'mail',  // Рассылки
                ],
                iconHeader: MessageOutlined,
            },
            crm: { // Пользователи
                items: [
                    'user',     // Таблица пользователей
                    'group',     // Списки (группы) пользователей
                    'field',     // Поля профиля
                ],
                iconHeader: UserOutlined,
            },
            sales: { // Продажи
                items: [
                    'order',
                    'product',
                    'category',
                    'utm',
                    // 'discount',
                    // 'invoice',
                ],
                iconHeader: ShoppingOutlined,
            },
            cms: { // Конструктор сайтов
                items: [
                    // 'divider',
                    'site',     // Сайты
                    'layout',    // Макеты
                    'theme',    // Макеты
                    'tag',      // Теги
                    // 'page',     // Страницы
                    // 'domain',   // Домены
                ],
                iconHeader: DesktopOutlined,
            },
            gamification: { // Геймификация
                items: [
                    'currency',       // Монеты / Валюты
                    // 'money',          // Банкноты / Деньги
                    'achievement',    // Достижения
                    'resource',       // Ресурсы
                    // 'role',        // Роли
                ],
                iconHeader: TrophyOutlined,
            },
            project: {  // Настройки проекта
                items: [
                    'about',   // Главная
                    'about#tariff',     // Тарифы и оплаты
                    'integration',  // Интеграции
                    'employee',    // Сотрудники
                    'edit#alerts',       // Уведомления (настройки)
                    // 'position',    // Должности
                    // 'department',  // Отделы
                ],
                // title: l('menu.project.title'),
                iconHeader: SettingOutlined,
            },
        }

        if (!dotenv.is_app_free) {
            if (site.list && site.list.length) {
                const siteItems = [];
                for (const siteItem of site.list) {
                    if (!siteItem.published_at) continue;
                    siteItems.push({
                        link: `${routes.project_list}/${project_item_id}/site/page/-1?site_id=${siteItem.id}`,
                        label: siteItem.title,
                    })
                }

                if (siteItems.length) {
                    main_menu_project.cms.items = [
                        ...siteItems,
                        'divider',
                        ...main_menu_project.cms.items
                    ]
                }
                // main_menu_project.cms.items = ['site', 'layout', 'theme', 'page', 'domain', 'tag']
            }

            // const paid_sections = [
            //     // 'dialog',
            //     // 'graph',
            //     'crm',
            //
            //     // sites
            //     'site',
            //     'layout',
            //     'theme',
            //     'tag',
            //     'page',
            //     'domain',
            // ]
            //
            // let allowed_sections = []
            // const now = getUtcDateTime()
            //
            // if (project_item.allowed_sections) {
            //     for (const allowedSection of project_item.allowed_sections) {
            //         if (allowedSection.expire_at > now) {
            //             allowed_sections.push(allowedSection.section)
            //
            //             if (allowedSection.section === 'page') {
            //                 allowed_sections.push('site')
            //                 allowed_sections.push('layout')
            //                 allowed_sections.push('theme')
            //                 allowed_sections.push('domain')
            //                 allowed_sections.push('tag')
            //
            //                 if (site.list && site.list.length) {
            //                     const siteItems = [];
            //                     for (const siteItem of site.list) {
            //                         if (!siteItem.published_at) continue;
            //                         siteItems.push({
            //                             link: `${routes.project_list}/${project_item_id}/site/page/-1?site_id=${siteItem.id}`,
            //                             label: siteItem.title,
            //                         })
            //                     }
            //
            //                     if (siteItems.length) {
            //                         main_menu_project.cms.items = [
            //                             ...siteItems,
            //                             'divider',
            //                             ...main_menu_project.cms.items
            //                         ]
            //                     }
            //
            //                     // main_menu_project.cms.items = ['site', 'layout', 'theme', 'page', 'domain', 'tag']
            //                 }
            //             }
            //         }
            //     }
            // }
            //
            // // console.log('main_menu_project', main_menu_project)
            //
            // let new_project_menu = {}
            // for (const menu_sect in main_menu_project) {
            //     const menu_section = main_menu_project[menu_sect]
            //
            //     if (!new_project_menu[menu_sect]) {
            //         new_project_menu[menu_sect] = {
            //             items: [],
            //             iconHeader: menu_section.iconHeader
            //         }
            //     }
            //
            //     for (const menu_item of main_menu_project[menu_sect].items) {
            //         if (inArray(menu_item, paid_sections) && !inArray(menu_item, allowed_sections)) continue;
            //         new_project_menu[menu_sect].items.push(menu_item);
            //     }
            // }
            //
            // // store result
            // main_menu_project = new_project_menu
        }
        else {
            main_menu_project.project.items = removeValueFromArray('tariff', main_menu_project.project.items)
        }

        if (project_item.id === dotenv.main_project) {
            main_menu_project.project.items.push('sect')
        }

        return <>
            <ButtonsLeftMenu emptyPathOnly={true} menuBtn={[
                {
                    name: 'project',
                    title: l('menu.project.about'),   // about project
                    buttonIcon: LineChartOutlined,
                    path: routes.project_list + '/' + project_item_id
                },
            ]}/>
            <MenuAccordions items={main_menu_project} currentProjectId={project_item_id}/>
        </>
    }
    else {
        const main_menu_user = {
            admin: { // Корабль
                items: [
                    'account',   // Личный кабинет
                    'settings',  // Редактировать профиль
                    'safety',    // Безопасность
                ],
                iconHeader: UserOutlined,
            },
        }
        const main_menu_items = [
            {
                name: 'project',
                title: l('menu.technology'),   // Остров технологий
                buttonIcon: RobotOutlined,
                path: routes.project_list
            },
            {
                name: 'help',
                title: l('menu.knowledge'),    // Остров знаний
                buttonIcon: BookOutlined,
                path: dotenv.help_url,
                external: true,
            },

            // {
            //     name: 'news',
            //     title: l('menu.news'),         // Новости
            //     buttonIcon: InfoCircleOutlined,
            //     restricted: true,
            //     path: '/news'
            // },
            // {
            //     name: 'club',
            //     title: l('menu.club'),         // Клуб
            //     buttonIcon: UsergroupAddOutlined,
            //     restricted: true,
            // },
            // {
            //     name: 'olympus',
            //     title: l('menu.olympus'),      // Олимп славы
            //     buttonIcon: BarChartOutlined,
            //     restricted: true,
            // }
        ]

        return <>
            <MenuAccordions items={main_menu_user} isOpened={true}/>
            <ButtonsLeftMenu menuBtn={main_menu_items}/>
        </>
    }
}

export default LeftMenu