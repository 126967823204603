// import CryptoJS from 'crypto-js'
// const pwd = 'Secret Passphrase'
import {decode, encode} from 'html-entities';
import {dotenv} from "../config/config";
import {l} from "./locale";
import moment from "moment-timezone";

import 'moment/locale/ru'
import 'moment/locale/uk'

moment.locale(dotenv.default_lang);

//--------------------------------------------------------------------------------------------
export const objectLength = (obj) => {
    if (obj === undefined || obj === null) return 0;
    return Object.keys(obj).length
}

export const variableLength = (val) => {
    if (val === undefined || val === null) return 0;
    return val.length
}

export const is = (val) => {
    if (val === undefined || val === null || val === '' || val === 0 || val === false) return false;
    else if (isArr(val) && val.length === 0) return false;
    else if (typeof val === 'object' && objectLength(val) === 0) return false;
    return true;
}

export const parseBool = (val) => {
    return !!val
}

export const textLengthInBytes = (text) => {
    if (!text) return 0
    let encoder = new TextEncoder();
    let bytes = encoder.encode(text);
    // console.log('bytes.length', bytes.length)
    return bytes.length
}

export const isMainProject = (project_id = 0, project = null) => {
    const project_id_int = toNum(project_id)
    if (project_id_int === dotenv.main_project) return true;

    if (project) {
        let projects_list = [...project.list, ...project.shared];
        const project_item = projects_list.find(obj => obj.id === dotenv.main_project);
        return project_item !== undefined && project_item.id && project_item.id > 0;
    }

    return false;
}

//--------------------------------------------------------------------------------------------
export const sleep = (ms) => {                                  // аналог sleep в php
    let date = Date.now(), current = null;
    do {
        current = Date.now();
    } while (current - date < ms);
}

export const getUtcDateTime = () => {
    return Math.round(Date.now() / 1000);
}

export const roundPrice = (value, as_percent = false, factor = 100) => {
    let val = value;
    if (as_percent) val *= 100;
    return Math.round(val * factor) / factor;
}

//--------------------------------------------------------------------------------------------
export const setEncryptedStorageItem = (data, item = dotenv.app_name) => {
    localStorage.setItem(item, encrypt(JSON.stringify(data)))
}
//--------------------------------------------------------------------------------------------
export const getEncryptedStorageItem = (item = dotenv.app_name) => {
    return JSON.parse(decrypt(localStorage.getItem(dotenv.app_name)))
}
//--------------------------------------------------------------------------------------------
export const setObjectToStorage = (item, data) => {
    localStorage.setItem(item, JSON.stringify(data));
}
//--------------------------------------------------------------------------------------------
export const getObjectFromStorage = (item) => {
    return JSON.parse(localStorage.getItem(item));
}
//--------------------------------------------------------------------------------------------
export const getItemStorage = (item) => {
    return localStorage.getItem(item);
}
//--------------------------------------------------------------------------------------------
export const setItemStorage = (item, data) => {
    localStorage.setItem(item, data);
}
//--------------------------------------------------------------------------------------------
export const removeItemStorage = (item = null) => {
    if (item)
        localStorage.removeItem(item);
    else
        localStorage.clear()
}
//--------------------------------------------------------------------------------------------
export const encrypt = (plain_text) => {
    return plain_text;
    // return CryptoJS.AES.encrypt(plain_text, pwd).toString();
}
//--------------------------------------------------------------------------------------------
export const decrypt = (crypto_text) => {
    return crypto_text;
    // const bytes = CryptoJS.AES.decrypt(crypto_text, pwd);
    // return bytes.toString(CryptoJS.enc.Utf8);
}
//--------------------------------------------------------------------------------------------
export const getDate = (time = new Date().getTime()) => {

    const addZero = (num) => {
        return num < 10 ? '0' + num : '' + num
    }

    const now = new Date(time)

    return {
        year: now.getFullYear(),
        month: addZero(now.getMonth() + 1),
        day: addZero(now.getDate()),
        hour: addZero(now.getHours()),
        minutes: addZero(now.getMinutes()),
        seconds: addZero(now.getSeconds())
    }
}
//--------------------------------------------------------------------------------------------
export const goto = (fun, p) => {
    document.querySelector('.root_app').classList.remove('full')
    const timer = setTimeout(() => {
        fun(p);
        clearTimeout(timer)
    }, 200)
}
//--------------------------------------------------------------------------------------------
export const setVisible = () => {
    const root = document.querySelector('.root_app');
    if (!root.classList.contains('full')) root.classList.add('full')
}
//--------------------------------------------------------------------------------------------
export const getParentNode = (node, classname) => {
    let current = node;
    let cls = current.classList;
    if (cls && cls.contains(classname)) return current;
    while (current.parentNode !== null && current.parentNode !== document.documentElement) {
        current = current.parentNode;
        cls = current.classList;
        if (cls && cls.contains(classname)) return current;
    }
    return 0;
}
//--------------------------------------------------------------------------------------------

export const navigate = (url) => {
    // console.log('Navigate to url:', url);
    // window.location.href = url;
    window.history.pushState(null, null, url);
    window.dispatchEvent(new Event("popstate"));
    return null;
}

export const redirect = (url, target = '_self') => {
    // console.log('redirect url', url)
    // window.location.assign(url);
    window.open(url, target);
}

export const replace = (url) => {
    window.history.replaceState(null, null, url);
    window.dispatchEvent(new Event("popstate"));
}

export const toNum = (value) => {
    let result = 0;
    try {
        result = Number(value);
        if (isNaN(result)) result = 0;
    } catch (e) {
        result = 0;
    }
    return result;
}

export const isArr = (value) => {
    // isArray conflicts with craco module
    return Array.isArray(value);
}

export const setFormField = (form, fieldName, fieldValue) => {
    let form_values = {}
    form_values[fieldName] = fieldValue;
    form.setFieldsValue(form_values);
}

export const ucFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const newTitleForCopy = (inputStr) => {
    let words = inputStr.split(' ');
    let lastWord = words[words.length - 1];

    let number = toNum(lastWord);

    if (number) {
        lastWord = number + 1;
    } else {
        lastWord = lastWord + ' 2';
    }

    words[words.length - 1] = lastWord;
    return words.join(' ');
}

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export const arrayToObject = (arr) => {
    const obj = {};
    for (let i = 0; i < arr.length; i++) {
        obj[i] = arr[i];
    }
    return obj;
}


export const getArrayFromObjectsField = (objectsArray, field) => {
    let array = [];
    objectsArray.forEach((element) => {
        array.push(element[field])
    })
    return array
}

export const objectToQueryString = (obj) => {
    let searchParams = new URLSearchParams();
    for (let property in obj) {
        let value = obj[property]
        if (!value && value !== 0) continue;

        if (typeof value === 'object') {
            for (let item of value) {
                searchParams.append(property, item)
            }
        } else {
            searchParams.append(property, value)
        }
    }
    return searchParams.toString();
}

export const deepSetOrig = (obj, fieldName, fieldValue) => {
    const setNestedFieldValue = (obj, keys, value) => {
        const [currentKey, ...remainingKeys] = keys;

        if (!remainingKeys.length) {
            obj[currentKey] = value;
        } else {
            obj[currentKey] = obj[currentKey] || {};
            setNestedFieldValue(obj[currentKey], remainingKeys, value);
        }
    };

    if (typeof fieldName === 'string') {
        fieldName = fieldName.split('.');
    }

    if (Array.isArray(fieldName)) {
        if (fieldName.length === 1) obj[fieldName[0]] = fieldValue;
        else setNestedFieldValue(obj, fieldName, fieldValue);
    } else {
        obj[fieldName] = fieldValue;
    }
};

export const deepSet = (obj, fieldName, fieldValue) => {
    const setNestedFieldValue = (obj, keys, value) => {
        const [currentKey, ...remainingKeys] = keys;

        if (!remainingKeys.length) {
            obj[currentKey] = value;
        } else {
            obj[currentKey] = obj[currentKey] || {};
            setNestedFieldValue(obj[currentKey], remainingKeys, value);
        }
    };

    const newObj = {...obj};
    if (typeof fieldName === 'string') fieldName = fieldName.split('.');
    // console.log('fieldName', fieldName)

    if (fieldName.length === 1) newObj[fieldName[0]] = fieldValue;
    else setNestedFieldValue(newObj, fieldName, fieldValue);

    return newObj;
};

export const deepSetToCurrent = (obj, path, value) => {
    const keys = Array.isArray(path) ? path : path.split('.');
    let current = obj;

    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        if (!current[key]) current[key] = {};
        current = current[key];
    }

    current[keys[keys.length - 1]] = value;
}

export const objDeepExtend = (first, second) => {
    for (const key in second) {
        if (second.hasOwnProperty(key)) {
            if (typeof second[key] === 'object' && !Array.isArray(second[key]) && second[key] !== null) {
                if (!first[key]) first[key] = {};
                objDeepExtend(first[key], second[key]);
            } else {
                if (typeof first !== 'object') continue;
                first[key] = second[key];
            }
        }
    }
}

export const flip = (obj) => {
  const inverted = {};
  for (const [key, value] of Object.entries(obj)) {
    inverted[value] = key;
  }
  return inverted;
};

export const deepGet = (object, path = '', undefinedValue = undefined) => {
    let value = object
    const keys = isArr(path) ? path : path.split(".");
    for (const param of keys) {
        if (typeof value !== 'object' || !value || !(param in value)) return undefinedValue;
        value = value[param]
    }
    return value;
}

export const getObjectValueByPath = (object, path = '', undefinedValue = undefined) => {
    return deepGet(object, path, undefinedValue)
}

// export const replaceLocaleVarsInObj = (t, obj) => {
//     // TODO: refactor. Is it need to optimize?
//     if (typeof obj === 'object') {
//         for (const key in obj) {
//             if (typeof obj[key] === 'object') {
//                 obj[key] = replaceLocaleVarsInObj(t, obj[key]);
//             } else if (typeof obj[key] === 'string') {
//                 const matches = obj[key].match(/{locale:(.*?)}/g);
//                 if (matches) {
//                     matches.forEach(match => {
//                         const text = match.match(/{locale:(.*?)}/)[1];
//                         obj[key] = obj[key].replace(match, t(text));
//                     });
//                 }
//             }
//         }
//     }
//     return obj;
// }

export const replaceLocaleVarsInObj = (t, obj) => {
    if (!obj) return obj;
    if (typeof obj === 'object') {
        Object.keys(obj).forEach(key => {
            if (!obj[key]) return;
            if (typeof obj[key] === 'object') {
                obj[key] = replaceLocaleVarsInObj(t, obj[key]);
            } else if (typeof obj[key] === 'string') {
                obj[key] = obj[key].replace(/{locale:(.*?)}/g, (match, text) => {
                    return t(text);
                });
            }
        });
    }
    return obj;
}

export const replaceVars = (str, vars, undefinedValue = 'undefined') => {
    if (!str || typeof str !== 'string') {
        // console.log('replaceVars - !str:', str)
        return str;
    }

    return str.replace(/{var:(.*?)}/g, (match, text) => {
        // if (text.contains('username')) return console.log('text', text, vars)
        return deepGet(vars, text, undefinedValue);
    });
}

export const replaceVarsInObj = (obj, vars, recursive = false, undefinedValue = 'undefined') => {
    if (obj && typeof obj === 'object') {
        Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'object') {
                if (!recursive) return;
                obj[key] = replaceVarsInObj(obj[key], vars, recursive, undefinedValue);
            } else if (typeof obj[key] === 'string') {
                // console.log('key', obj[key])
                obj[key] = replaceVars(obj[key], vars, undefinedValue);
            }
        });
    }
    return obj;
}

export const findObjectByField = (objects, field = 'id', value, defaultObject = {}) => {
    const object = objects.find(obj => {
        return obj[field] === value;
    })
    if (!object) return defaultObject;

    return object;
}

export const orderObjectsByExternalValues = (objects, values, field = 'id') => {
    let result = [];
    values.forEach((element) => {
        const object = objects.find(obj => {
            return obj[field] === element;
        })
        if (object) result.push(object);
    })
    return result;
}

export const orderObjectsByFieldValues = (array, key) => {
    return array.sort(function (a, b) {
        let x = a[key];
        let y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}

export const inArray = (value, array) => {
    return array.indexOf(value) > -1
}

export const isObjectsEqual = (left, right) => {
    return JSON.stringify(left) === JSON.stringify(right);
}

export const getIntArrayFromSearchArray = (searchParams, field, defaultArray = []) => {
    if (searchParams.has(field)) {
        let fieldValue = searchParams.getAll(field)
        if (fieldValue) return fieldValue.map((element) => {
            return parseInt(element)
        })
    }
    return defaultArray
}

export const addUniqueValueToArray = (value, array) => {
    if (!array) return [value] // crutch because of [NaN]
    if (!inArray(value, array)) array.push(value)
    return array
}

export const arrayUnique = (array) => {
    return Array.from(new Set(array));
}

export const arrayReorder = (values, indexOld, indexNew) => {
    const result = Array.from(values);
    const [removed] = result.splice(indexOld, 1);
    result.splice(indexNew, 0, removed);

    return result;
}

export const arrayMove = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
}

export const removeIndexFromArray = (index, array) => {
    array.splice(index, 1);
    return array;
}

export const removeValueFromArray = (value, array) => {
    return array.filter((arrayValue, index) => {
        return arrayValue !== value;
    })
}

export const filterObjByKeys = (obj, allowedFields, excluded = null) => {
    let filteredObj = {};
    Object.keys(obj).forEach(key => {
        if (allowedFields.includes(key) && (!excluded || !excluded.includes(key))) {
            filteredObj[key] = obj[key];
        }
    });

    return filteredObj;
}

export const assocArrayByField = (array, fieldName = 'id', deepCopy = false) => {
    let result = {};
    for (const item of array) {
        const field_value = item[fieldName]
        if (field_value !== undefined) {
            result[field_value] = deepCopy ? deepCopyObject(item) : item
        }
    }
    return result
}

export function arrayMatchesCount(value, array) {
    const matchingStrings = array.filter(item => item === value);
    return matchingStrings.length;
}


export const filterArrayByFieldValue = (array, fieldName, searchValue) => {
    return array.filter((object, index) => {
        return object[fieldName] === searchValue;
    })
}

export const filterArrayByPathValue = (array, fieldPath, searchValue) => {
    return array.filter((object, index) => {
        return deepGet(object, fieldPath) === searchValue;
    })
}


export const onFolderOpen = (id, isOpen, searchParams, setSearchParams) => {
    // console.log('onFolderOpen', id, isOpen)
    let openedFolders = getIntArrayFromSearchArray(searchParams, 'folder', [])

    if (isOpen) openedFolders = addUniqueValueToArray(id, openedFolders)
    else openedFolders = removeValueFromArray(id, openedFolders)
    // set to url
    searchParams.delete('folder') // set to nothing
    for (const openedFolder of openedFolders) {
        searchParams.append('folder', openedFolder) // add new values
    }
    setSearchParams(searchParams)
}

export const getItemValues = (item, fields = []) => {
    let result = {}

    for (const field of fields) {
        if (typeof field === 'object') {
            if (!field.name) continue

            if (field.name.includes('.')) {
                const fieldValue = deepGet(item, field.name);
                deepSetToCurrent(result, field.name, fieldValue);
            } else result[field.name] = item[field.name]
        }
        // for some cases like password form
        else result[field] = item[field]
    }

    return result
}

export const setDefaultValues = (item, values = {}) => {
    return {...item, ...values}
}

export const setObjectValues = (item, values = {}) => {
    for (const [key, value] of Object.entries(values)) {
        // if (item[key]) item[key] = value     // работает не верно - если значение поля в item = "", оно не заменяется новым значение из values
        item[key] = value; // TODO FIX
    }
    return item
}

export const createObjectFromObjectsArray = (array = [], nameKey = 'id', valueKey = 'title') => {
    let result = {};
    for (const item of array) {
        const item_name = deepGet(item, nameKey);
        const item_title = deepGet(item, valueKey);
        result[item_name] = item_title ?? String(item_name);
    }
    return result;
}

export const filterSelectOptionsByContent = (input, option) => {
    // console.log(option)
    return option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export const filterSelectOptionsByLabel = (input, option) => {
    // console.log(input, option)
    let label = option.label ?? option.children;
    if (!label) return false;

    if (typeof label === 'string') label = label.toLowerCase()
    else label = String(label)

    return label.indexOf(input.toLowerCase()) >= 0;
}

export const formRequiredRules = [{
    required: true,
    message: l("error.validation.required")
}]

export const deepCopyObject = (obj) => {
    if (!obj) return obj;
    return JSON.parse(JSON.stringify(obj));
}

// --------------------------------------------------------------------------------------
export const moduleType = (effect) => {
    return effect.type.split('/')[0];
}

export const createMarkup = (markupText) => {
    return {
        __html: markupText
    };
};

export const convertTextToHtmlEditable = (text) => {
    return encode(text).replace(/\n/g, "<br/>");
}

export const convertHtmlToTextEditable = (html) => {
    return decode(html.replace(/<br\/?>/g, "\n"));
}

export const convertLineBrakeToBr = (text, tagName = '<br/>') => {
    // Regex to match line breaks not inside <pre> or <code> tags
    const lineBreakOutsidePreCodeRegex = /(?!<pre>[^]*?)\n(?![^]*?<\/pre>)/g;

    // Replace matched line breaks with <br/>
    return text.replace(lineBreakOutsidePreCodeRegex, tagName);
}

export const convertTextToHtml = (text, pTag = 'p') => {
    let t = convertLineBrakeToBr(encode(text), '<br/>');
    let chunks = t.split('<br/><br/>');
    let result = [];
    for (const chunk of chunks) {
        result.push(`<${pTag}>${chunk}</${pTag}>`);
    }
    return result.join('<div></div>');
}


export const convertBrToLineBrake = (text) => {
    return text.replace(/<br\/?>/g, "\n");
}

export const urlsToLinks = (text) => {
    // Regex to identify URLs not already in an <a> tag
    const unlinkedUrlRegex = /(?!<a [^>]*href=")https?:\/\/[^\s<"'!,:;(){}*\[\]]+/g;

    if (!unlinkedUrlRegex.test(text)) {
        return text; // No unlinked URLs found, return original text
    }

    // Replace unlinked URLs with <a> tags
    return text.replace(unlinkedUrlRegex, '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>');
}


export const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(convertBrToLineBrake(html), 'text/html');
    return doc.body.textContent || '';
}

export const downloadFileFromText = (text, fileName = 'file', format = 'txt') => {
    const formatMap = {
        'json': 'application/json',
        'csv': 'text/csv',
        'txt': 'text/plain'
    };

    const fileType = formatMap[format] || 'text/plain';
    const file = new Blob([text], {type: fileType + ';charset=utf-8'});
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.${format}`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element); // Clean up
}

export const isAnythingIsFocused = () => {
    return document.hasFocus() &&
        document.activeElement !== null &&
        document.activeElement !== document.body &&
        document.activeElement !== document.documentElement;
}

export const getSearchFilters = (searchParams) => {
    const params = new URLSearchParams(searchParams);
    const obj = {};

    for (const [key, value] of params.entries()) {
        if (Array.isArray(obj[key])) {
            obj[key].push(value);
        } else if (obj.hasOwnProperty(key)) {
            obj[key] = [obj[key], value];
        } else {
            obj[key] = value;
        }
    }

    return obj;
};


export const setSearchFilters = (filters, setSearchParams) => {
    const newSearchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(filters)) {
        if (value !== undefined) {
            if (isArr(value)) {
                value.forEach((val) => {
                    newSearchParams.append(key, val);
                });
            } else {
                newSearchParams.set(key, value);
            }
        }
    }
    // console.log('newSearchParams', newSearchParams)
    setSearchParams(newSearchParams);
};

export const compareObjects = (firs, last) => {
    return JSON.stringify(firs) === JSON.stringify(last);
}

export const unixFromMoment = (moment) => {
    return Math.round(moment.valueOf() / 1000)
    // return Math.round(moment.valueOf() / 1000) - (dotenv.timezoneOffset * 60)
    // return Math.round(moment.clone().tz(dotenv.timezone).valueOf() / 1000)
}

export const momentFromUnix = (unix, format = null) => {
    // console.log('momentFromUnix', unix, format);

    let result = moment.unix(Number(unix));
    // let result = moment.tz(Number(unix) * 1000, dotenv.timezone)

    if (format) {
        result = result.format(format);
    }

    return result;
}

export const convertMomentParamsToUnix = (params) => {
    if (!params) return params;

    const result = {...params};
    for (const [key, value] of Object.entries(params)) {
        if (value && value._isAMomentObject) {
            result[key] = unixFromMoment(value);
        }
    }

    return result;
}

export const convertUnixParamsToMoment = (params) => {
    if (!params) return params;

    const result = {...params};
    for (const [key, value] of Object.entries(params)) {
        // if key contains _since, _until, _at:
        if (value && (key.includes('_since') || key.includes('_until') || key.includes('_at'))) {
            result[key] = momentFromUnix(value);
        }
    }

    return result;
}

export const isQuotaExceededError = (err = null) => {
    return (
        err instanceof DOMException &&
        // everything except Firefox
        (err.code === 22 ||
            // Firefox
            err.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            err.name === "QuotaExceededError" ||
            // Firefox
            err.name === "NS_ERROR_DOM_QUOTA_REACHED")
    );
}

export const getLabelForNumeric = (number = 0, labels = []) => {
    const lastDigit = number.toString().slice(-1);
    if (lastDigit === '1') {
        return labels[1];
    } else if (['2', '3', '4'].includes(lastDigit)) {
        return labels[2];
    } else {
        return labels[0];
    }
}

export const numberForHuman = (number) => {
    const parts = number.toString().split('.');
    const integerPart = parts[0];
    const decimalPart = parts[1] || '';

    let formattedIntegerPart = integerPart;
    if (integerPart.length > 4) formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    if (decimalPart !== '') {
        return `${formattedIntegerPart}.${decimalPart}`;
    }

    return formattedIntegerPart;
};

export const getUserFullName = (user) => {
    if (!user.id) return 'Undefined';
    const userName = [user.first_name, user.title, user.last_name].filter(e => e).join(' ')
    if (!userName) return 'User ' + user.id;
    return userName;
}

export const jsonParseOrDef = (str, def = null) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return def;
    }
}

export const getBotUrl = (item, start = false) => {
    if (!item) return undefined;
    let url = null;

    const platform = deepGet(item.integration, 'platform');
    if (!platform) return undefined;

    if (platform === 'tg') {
        const itemParams = deepGet(item.integration, 'params');
        if (!itemParams) return undefined;

        const paramsObject = jsonParseOrDef(itemParams);
        if (!paramsObject) return undefined;

        const botName = deepGet(paramsObject, 'username');
        if (!botName) return undefined;

        url = `https://t.me/${botName}`
        if (start) url += '?start=start'
    }

    return url;
}

export const getFileSizeString = (bytes, t = null) => {
    let units = 'kb';
    let size = bytes / 1024;

    if (size > 1024) {
        units = 'mb';
        size /= 1024;
    }

    if (size > 1024) {
        units = 'gb';
        size /= 1024;
    }

    let result = numberForHuman(roundPrice(size));
    if (t) return result + ' ' + t('common.size.' + units);
    return result
}


export const pasteOnlyText = (e) => {
    e.preventDefault();
    const text = e.clipboardData ? e.clipboardData.getData("text/plain") : "";

    if (document.queryCommandSupported?.("insertText")) {
        return document.execCommand("insertText", false, text);
    }

    const selection = document.getSelection();
    if (!selection) return;
    const range = selection.getRangeAt(0);
    range.deleteContents();
    range.insertNode(new Text(text));
    range.collapse(); // select nothing
    selection.removeAllRanges(); // position caret after inserted text
    selection.addRange(range); // show caret
}




